<template>
  <KDialog
    :loading="loading"
    :title="title"
    :visible="visible"
    showClose
    :actions="actions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <k-form ref="form">
      <v-row class="ma-0">
        <!-- Nome da Configuração -->
        <v-col cols="12">
          <KLabel dense outlined label="Nome da Configuração">
            {{ form.name }}</KLabel
          >
        </v-col>

        <!-- Descrição da Configuração -->
        <v-col cols="12">
          <KLabel dense outlined readonly label="Descrição da Configuração">
            {{ form.description }}
          </KLabel>
        </v-col>

        <!-- Valor da Configuração -->
        <v-col cols="12">
          <v-text-field
            v-show="isFreeText"
            dense
            outlined
            label="Valor da Configuração"
            v-model="temp.freeText"
            :error-messages="errors.value"
            persistent-hint
            required
          ></v-text-field>

          <KInputMasked
            v-show="isMasked"
            dense
            outlined
            label="Valor da Configuração"
            :error-messages="errors.value"
            v-model="temp.maskedText"
            :hint="maskOnTheFly"
            persistent-hint
            return-masked
            required
            :mask="maskOnTheFly"
          ></KInputMasked>

          <KInputMoney
            v-show="isCurrency"
            type="number"
            :label="`Valor da Configuração`"
            dense
            outlined
            :error-messages="errors.value"
            v-model="temp.currencyText"
            prefix="R$"
          />
        </v-col>
      </v-row>

      {{ form.rule }}
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KInputMoney from "@/components/KInput/Money";
import KInputMasked from "@/components/KInput/Masked";

//
import { mask } from "vue-the-mask";
mask.masked = false;

export default {
  components: {
    KForm,
    KLabel,
    KDialog,
    KInputMoney,
    KInputMasked
  },

  directives: { mask },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      form: {},

      temp: {},

      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {
        name: null,
        slug: null,
        icon: null
      }
    };
  },

  computed: {
    actions() {
      if (this.isEditing) {
        return [
          {
            eventName: "click:edit",
            label: "Salvar Alterações"
          }
        ];
      }

      return [{ eventName: "click:add", label: "Cadastrar" }];
    },

    maskOnTheFly() {
      if (this.form.mask) {
        return this.form.mask;
      }

      return "!";
    },

    isFreeText() {
      return !this.form.mask;
    },

    isMasked() {
      return (
        this.form.mask &&
        this.form.mask !== "currency" &&
        this.form.mask !== "date"
      );
    },

    isCurrency() {
      return this.form.mask && this.form.mask === "currency";
    },

    isDate() {
      return this.form.mask && this.form.mask == "date";
    }
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        this.errors = {};
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;

          let value;

          // Se for campo livre
          if (this.isFreeText) value = this.temp.freeText;
          // Se for máscara normal
          if (this.isMasked) value = this.temp.maskedText;
          // Se for máscara de dinheiro
          if (this.isCurrency) value = this.temp.currencyText;

          //
          await this.$store.dispatch("settings/save", {
            payload: { id: this.form.id, value },
            editing: this.isEditing
          });

          this.loading = false;
          this.$message.success("Registro salvo com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para cadastrar um novo dado
     */
    openToAdd() {
      // Altera o título
      this.title = "Cadastrar nova configuração";

      // Limpa os dados
      this.clear();

      // Define se é uma edição
      this.isEditing = false;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit({ id, name, description, value, mask, rule }) {
      // Altera o título
      this.title = `Editar "${description}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.form = {
        id,
        name,
        description,
        value,
        mask,
        rule
      };

      if (this.isFreeText) this.$set(this.temp, "freeText", value);
      if (this.isMasked) this.$set(this.temp, "maskedText", value);
      if (this.isCurrency) this.$set(this.temp, "currencyText", value);

      // Define se é uma edição
      this.isEditing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    async clear() {
      this.form = {};
      this.temp = {};
      this.errors = {};
      await this.$nextTick();
      this.$delete(this.temp, "freeText");
      this.$delete(this.temp, "maskedText");
      this.$delete(this.temp, "currencyText");
    }
  }
};
</script>

<style>
</style>
