<template>
  <KPage>
    <!--  -->
    <FilterBar />

    <!--  -->
    <v-card outlined class="overflow-hidden mb-6">
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <v-list-item
              v-for="(item, key) in settings"
              :key="key"
              @click.stop="() => edit(item)"
            >
              <v-list-item-avatar>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.description"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  <KChip blue>{{ item.name }}</KChip>
                  <KChip v-if="!item.value" orange>Vazio</KChip>
                  {{ item.value }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import KChip from "@/components/KChip";
import DialogSave from "./DialogSave";
import FilterBar from "./FilterBar";

export default {
  components: {
    KPage,
    KForm,
    KAvatar,
    KChip,
    DialogSave,
    FilterBar
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    settings() {
      return this.$store.getters["settings/all"];
    }
  },

  //
  methods: {
    /**
     * Abre o dialog como edição
     */
    edit(data) {
      this.$refs["dialog"].openToEdit(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("settings/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.loading = false;
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>
